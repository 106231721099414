@tailwind base;
@tailwind components;
@tailwind utilities;

.tiptap {
  outline: none;
  border: none;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

@font-face {
  font-family: 'Beirut';
  font-weight: normal;
  font-style: normal;
  src: url('../src/assets/fonts/BeirutDisplayVariableUpright.woff2')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Messina';
  font-weight: normal;
  font-style: normal;
  src: url('../src/assets/fonts/MessinaSansVariableUpright.woff2')
    format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Messina-semibold';
  font-weight: 600;
  font-style: normal;
  src: url('../src/assets/fonts/MessinaSansVariableUpright.woff2')
    format('woff2');
  font-display: swap;
}

* {
  font-family: 'Messina';
}

.title {
  font-family: 'Beirut';
}

.semibold {
  font-family: 'Messina-semibold';
}
